.form-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.form-field {
  display: flex;
  flex-direction: column;
}

.form-field-disabled {
  opacity: 0.5;
}

.form-field label {
  font-weight: 500;
  margin-bottom: 10px;
}

.form-field-asterisk {
  color: #df5657;
}

.form-field-error {
  display: flex;
  margin-top: 8px;
  height: 16px;
  color: #df5657;
  font-size: 0.875rem;

  .form-field-error-img {
    height: 1rem;
    border: 0.1rem solid transparent;
  }

  span {
    padding-left: 2px;
  }
}

.form-field input {
  background-color: #e7ebed;
  outline: none;
  border: none;
  height: 40px;
  border-radius: 4px;
  font-size: 16px;
  padding: 0rem 0.5rem;

  &:focus {
    border: #4f9aca 1px solid;
  }
}

.form-field textarea {
  background-color: #e7ebed;
  outline: none;
  border: none;
  height: 40px;
  border-radius: 4px;
  font-size: 16px;
  padding: 0rem 0.5rem;

  &:focus {
    border: #4f9aca 1px solid;
  }
}

.form-field .subtitle {
  margin-left: 1em;
  font-size: 0.8em;
  color: #4f9aca;
  cursor: pointer;
}

.form-field .subtitle-no-action {
  margin-left: 1em;
  font-size: 0.8em;
  color: #3D454D;
}

.form-row > div {
  flex: 1;

  &:not(:last-child) {
    padding-right: 30px;
  }
}
